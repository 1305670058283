import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import loadable from '@loadable/component';
import useApi, { GET_PLAYLISTS_PROGRESS } from '~hooks/useApi';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { POPUPS_MAP } from '~layout/Popups';
import LoadingSpinner from '~components/LoadingSpinner';
import { extractKnownSlices, extractSeoData } from '~src/utils/unfurl-helpers';
import { collectHomePagePlaylistIds } from '~src/utils/playlist-helpers';
import useConversionFramework from '~hooks/useConversionFramework';

const Page = loadable(() => import('~layout/Page'));
const SEO = loadable(() => import('~layout/SearchEngineOptimization'));
const SliceToComponent = loadable(() => import('~components/slices/slices'), {
  fallback: (
    <div style={{ minHeight: '50vh' }}>
      <LoadingSpinner />
    </div>
  )
});
const Popups = loadable(() => import('~layout/Popups'));

/**
 * NOTE: IMPORTANT: This is a special flag to control rendering of the page.
 * We need to be able to test the changes to the website's home page,
 * specifically, we want to put a `video_list` slice to there.
 * However, if we do that in Prismic, the change will immediately become visible for the live users.
 * So, the idea is this.
 * 1. We set this flag to `true`, so that this page will ignore the `video_list` slice. <-- we are here
 * 2. We release this code, with the flag set to `true`, live, to production.
 * 3. We change the flag to `false`, so that in staging we'll see the `video_list` slice, and will be able to change it however we want.
 * 4. After all the necessary testing and changes, we release the version with the flag set to `false`, live.
 */
const SHOULD_HIDE_VIDEOS_LIST_SLICE_ON_HOME_PAGE = false;

const unfurl = prismicData => ({
  slices: extractKnownSlices(prismicData, 'prismicTishaBavHome.data.body').filter(
    SHOULD_HIDE_VIDEOS_LIST_SLICE_ON_HOME_PAGE
      ? slice => slice.slice_type !== 'video_list'
      : () => true
  ),
  disableNewUserLandingPage:
    !!prismicData?.prismicTishaBavHome?.data?.disable_new_user_landing_page,
  seo: extractSeoData(prismicData, 'prismicHome')
});

const IndexPage = ({ data, location }) => {
  const { session } = useUserContext();
  const [progressState, getProgress] = useApi();
  const { isMarketingSite } = useConversionFramework();
  const { seo, slices, disableNewUserLandingPage } = unfurl(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const playlistIds = useMemo(() => collectHomePagePlaylistIds({ slices }), []);

  const { state } = location;
  const { isThankYouPopup = false } = state || {};
  const { isSubscriber, isAdmin, isLoggedIn, isUserStateLoading, jwtAvailable } = session;
  const { data: progressData } = progressState;

  const progress = (progressData && progressData.progress) || {};

  const shouldRedirectToWelcomeInMarketingSite = isMarketingSite && !disableNewUserLandingPage;
  const shouldRedirectToWelcomeInNonMarketingSite =
    !isMarketingSite && !disableNewUserLandingPage && !jwtAvailable && !isLoggedIn;
  const shouldRedirectToWelcome =
    shouldRedirectToWelcomeInMarketingSite || shouldRedirectToWelcomeInNonMarketingSite;

  useEffect(() => {
    if (!isLoggedIn || isUserStateLoading) {
      return;
    }

    if (!progressData && playlistIds?.length) {
      const progressParams = GET_PLAYLISTS_PROGRESS({ playlistIds });
      getProgress(progressParams);
    }
  }, [isLoggedIn, isUserStateLoading, playlistIds, progressData, getProgress]);

  useEffect(() => {
    // if user is not logged in, let's redirect to new user landing page
    if (shouldRedirectToWelcome) {
      navigate('/', { replace: true });
    }
  }, [shouldRedirectToWelcome]);

  if (shouldRedirectToWelcome) {
    // We don't want non logged in users to see this page, during the time of redirection
    return null;
  }

  return (
    <Page showContentAfterPageLoad>
      <SEO {...seo} hasDefaultStructuredDataObject />

      <SliceToComponent
        slices={slices}
        progress={isLoggedIn ? progress : {}}
        isAdmin={isAdmin}
        isUserStateLoading={isUserStateLoading}
        isSubscriber={isSubscriber}
        isLoggedIn={isLoggedIn}
      />

      {isThankYouPopup && <Popups which={POPUPS_MAP.THANK_YOU_FOR_NOT_CANCELLING} />}
    </Page>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape()
};

IndexPage.defaultProps = {
  location: {}
};

export default withPrismicPreview(IndexPage);

export const query = graphql`
  {
    prismicTishaBavHome {
      _previewable
      data {
        disable_new_user_landing_page
        body {
          __typename
          ... on PrismicTishaBavHomeDataBodyProducersCircleMembers {
            slice_type
            items {
              member_name
            }
          }
          ... on PrismicTishaBavHomeDataBodyExpansionPanel {
            slice_type
            primary {
              panel_title
              category
            }
            items {
              panel_materials {
                url
                document {
                  ... on PrismicMaterial {
                    data {
                      title
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyContinueWatching {
            slice_type
            primary {
              continue_watching_playlist
              theme
            }
          }
          ... on PrismicTishaBavHomeDataBodyTeamSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              team_member_avatar {
                url
              }
              team_member_name {
                html
              }
              team_member_position {
                html
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyJobSection {
            slice_type
            primary {
              job_section_title {
                html
                text
              }
              job_section_content {
                html
              }
              job_section_background
              job_section_button_title
              job_section_button_color
              job_section_button_link {
                url
              }
              job_section_image {
                url
                alt
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyFaqSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyAudioFileSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            audio_files: items {
              audio_file_link {
                url
              }
              title
              description {
                html
              }
              transcript {
                html
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyPromotionalBox {
            slice_type
            primary {
              promotional_box_link
              promotional_box_text
              text_color
              promotional_box_background_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicTishaBavHomeDataBodyResources {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              is_featured
              resource {
                uid
                url
                document {
                  __typename
                  ... on PrismicMaterial {
                    data {
                      title
                      description
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                      preview_image {
                        url
                      }
                    }
                  }
                  ... on PrismicParshat {
                    data {
                      title
                      slices {
                        ... on PrismicParshatDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicHolidayRedesign {
                    data {
                      title
                      body {
                        ... on PrismicHolidayRedesignDataBodyOffer {
                          id
                          slice_type
                          primary {
                            offer_title
                            offer_name
                            offer_description {
                              html
                            }
                            coupon_code
                            cta_text
                            cta_url
                            background_color
                            text_color
                            description_text_color
                            cta_background_color
                            cta_text_color
                            subscription_level
                          }
                        }
                        ... on PrismicHolidayRedesignDataBodyHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAdvanced {
                    data {
                      title
                      slices {
                        ... on PrismicAdvancedDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    data {
                      title
                      description_text: description {
                        text
                      }
                      parent_page {
                        uid
                      }
                      seo_image {
                        url
                      }
                    }
                  }
                  ... on PrismicBlogPost {
                    data {
                      title_text: title {
                        text
                        html
                      }
                      content {
                        text
                      }
                      seo_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyAuthorTestimonials {
            slice_type
            primary {
              author {
                document {
                  ... on PrismicAuthor {
                    data {
                      name
                      role
                      avatar {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              testimonial
            }
          }
          ... on PrismicTishaBavHomeDataBodyAboutPageAboutUs {
            slice_type
            primary {
              section_title {
                html
                text
              }
              about_us_bg_color
            }
            sections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
              topic_image {
                url
                alt
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyAboutTestimonials {
            slice_type
            primary {
              testimonials_background_color
            }
            testimonials: items {
              avatar {
                alt
                url
              }
              title
              subtitle
              name
              subscription_plan
            }
          }
          ... on PrismicTishaBavHomeDataBodyAboutVideosPath {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              section_footnote {
                html
              }
              slider_background_color
            }
            slides: items {
              title {
                html
              }
              subtitle {
                html
              }
              first_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              second_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              third_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyAboutTeam {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            members: items {
              avatar {
                alt
                url
              }
              name {
                html
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyAboutFaq {
            slice_type
            primary {
              section_title {
                html
                text
              }
              faq_bg_color
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            faqSections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyAboutCta {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_content {
                html
              }
              section_image {
                alt
                url
              }
            }
            links: items {
              title {
                html
              }
              subtitle {
                html
              }
              to {
                url
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyFeaturedPlaylist {
            slice_type
            primary {
              featured_playlist {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyVideoList {
            slice_type
            primary {
              theme
              title_video_list {
                html
                text
              }
              event_date {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyOneLineEmailAcquisition {
            slice_type
            primary {
              cta_text
              one_line_email_background_color
              cta_text_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicTishaBavHomeDataBodyPlaylists {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                type
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      cover_image {
                        url
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                  ... on PrismicPodcastPlaylist {
                    uid
                    data {
                      podcast_title {
                        text
                      }
                      hosts {
                        ... on PrismicPodcastPlaylistDataHostsHost {
                          items {
                            host {
                              document {
                                ... on PrismicAuthor {
                                  data {
                                    name
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      card_thumbnail {
                        url
                      }
                      podcast_thumbnail {
                        url
                      }
                      cover_color
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyTeamPageFounder {
            slice_type
            primary {
              founder_section_title {
                html
                text
              }
              founder_name {
                html
              }
              founder_position {
                html
              }
              founder_text {
                html
              }
              founder_image {
                url
              }
              founder_content_title {
                html
              }
            }
            items {
              founder_publication_title
              founder_publication {
                url
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyCustomHtml {
            slice_type
            primary {
              custom_html {
                html
                text
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyFaqHero {
            slice_type
            primary {
              hero_title {
                html
              }
              hero_subtitle {
                html
              }
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyHolidayHeroSplitScreen {
            slice_type
            primary {
              overline_text {
                html
                text
              }
              hero_title {
                html
                text
              }
              hero_image_split_direction
              hero_subtitle {
                html
                text
              }
              hero_background_image {
                url
                alt
              }
              hero_mobile_background_image {
                url
                alt
              }
              label_text {
                html
                text
              }
              label_text_color
              label_text_background_color
              subscription_level
              theme
              hero_featured_video {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyOffer {
            id
            slice_type
            primary {
              offer_title
              offer_name
              offer_description {
                html
              }
              coupon_code
              cta_text
              cta_url
              background_color
              text_color
              description_text_color
              cta_background_color
              cta_text_color
              subscription_level
            }
          }
          ... on PrismicTishaBavHomeDataBodyHeroTextToc {
            slice_type
            primary {
              title
              overline_rich_text {
                html
                text
              }
              underline_rich_text {
                html
                text
              }
              description
              navigation_menu_title
              text_color
              hero_background_color
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyHeroTextImage {
            slice_type
            primary {
              title
              subtitle
              link {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
              link_title
              text_color
              background_color
              hero_button_background_color
              hero_button_text_color
              foreground_image {
                alt
                url
              }
              background_image {
                alt
                url
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyTextBlock {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content {
                html
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyTextBlockCols {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content_left {
                html
              }
              text_block_content_right {
                html
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyAboutHero {
            slice_type
            primary {
              hero_text {
                html
              }
              underline_text {
                html
              }
              hero_background_image {
                url
                alt
              }
              hero_head_image {
                url
                alt
              }
              hero_featured_video {
                url
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodySpotlightPlaylists {
            slice_type
            id
            primary {
              subscription_level
              background_color
              text_color
              spotlight_section_bottom_divider {
                url
                alt
              }
            }
            items {
              spotlight_title
              spotlight_playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                      }
                      cover_image {
                        url
                        alt
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTishaBavHomeDataBodyFeaturedCourse {
            slice_type
            id
            primary {
              featured_course_title {
                html
              }
              featured_course_description {
                html
              }
              section_background_color
              background_color
              text_color
              top_label
              top_label_background_color
              top_label_text_color
              cta_text
              cta_url
              featured_course_image {
                url
                alt
              }
              bottom_shape_divider {
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`;
